function init() {
  [].slice.call(document.querySelectorAll(".mobile-reveal")).map(function(el) {
    var toggle = el.querySelector(".mobile-reveal-toggle");
    var content = el.querySelector(".mobile-reveal-content");

    toggle.addEventListener(
      "click",
      function(e) {
        content.classList.toggle("is-hidden-mobile");
      },
      false
    );
  });
}
window.addEventListener("load", init, false);
